* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Catamaran', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #c0d8e0;
  transition: 3s linear background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  text-decoration: none;
}
